/* styles.css */
.button-heading {
  background-color: #007bff; /* Primary button color */
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  display: inline-block;
}

.button-heading:hover {
  background-color: #0056b3; /* Darker shade for hover effect */
}

.form-groupnew{
  margin-left: -12px;
  width: 45%;
  margin-bottom: 10px;
}
