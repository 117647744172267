.footer{
    color: black;
    background-color: #FFCCF5;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 8vw;
    padding-top: 80px;
}

.footer-content{
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    gap: 80px;
}

.footer-content-left, .footer-content-right, .footer-content-center{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
}

.footer-content-left, .footer-content-right li, .footer-content-center li{
    list-style: none;
    margin-bottom: 10px;
    cursor: pointer;
}

.footer-content-right h2, .footer-content-center h2{
    color: black;
}

.footer-social-icons{
display: flex;
gap: 15px;
cursor: pointer;
}

.footer hr{
    width: 100%;
    height: 2px;
    margin: 20px 0;
    background-color: gray;
    border: none;
}

.footer .logo{
    width: 150px;
}
.footer-content-center{
    text-decoration: none;
    color: black;
}

@media (max-width:750px) {
    .footer{
        color: black;
        background-color: #FFCCF5;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 20px 8vw;
        padding-top: 40px;
    }
    .footer-content{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        font-size: 15px;
    }
    .footer-content-right h2, .footer-content-center h2{
       font-size: 18px;
    }
    
}