/* styles.css */
.custom-button-executive {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-button-executive:hover {
    background-color: darkred;
  }
  
  .custom-button-executive:active {
    background-color: maroon;
  }
  