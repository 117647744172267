
.landing-container {
    margin-bottom: 50px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (min-width: 768px) {
    .landing-container {
        flex-direction: row;
        align-items: flex-start;
    }
}

.landing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

@media (min-width: 768px) {
    .landing-content {
        flex-direction: row;
        justify-content: space-around;
        gap: 50px;
    }
}

.left-content {
    margin-top: 1px;
    width: 80%;
    flex-direction: column;
}

@media (min-width: 768px) {
    .left-content {
        
        width: 50%;
    }
}

.left-content h2 {
    font-size: max(5vw, 25px);
    text-align: center;
}

@media (min-width: 768px) {
    .left-content h2 {
        font-size: max(3vw, 25px);
    }
}

.left-content p {
    font-size: 18px;
    /* margin-left: 40px; */
    text-align: justify;
}


@media (min-width: 768px) {
    .left-content p {
        font-size: 20px;
    }
}

.right-content {
    margin-top: 2em;
    width: 90%;
    height: auto;
}

@media (min-width: 768px) {
    .right-content {
        width: 50%;
        height: auto;
    }
}

.right-content img {
    width: 100%;
}

@media (max-width: 767px) {
    .landing-container {
        padding: 10px;
    }

    .left-content,
    .right-content {
        width: 100%;
    }
}

@media (max-width: 450px) {
    .left-content {
        align-items: center;
        justify-content: center;
    }
}
