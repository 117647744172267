.table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  
  .table th {
    background-color: #f2f2f2;
  }
  
  .bank-cell {
    font-weight: bold;
  }
  
  .account-cell {
    font-style: italic;
  }
  

  .filter-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .filter-container label {
    margin-right: 10px;
  }
  
  .filter-container input[type="date"] {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
  }
  
  .filter-container button {
    padding: 8px 16px;
    border-radius: 5px;
    border: 1px solid #0d6efd;
    background-color: #0d6efd;
    cursor: pointer;
    color: white;
  }
  