.filter-container {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 10px;
  }
  
  .filter-container input {
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  @media only screen and (max-width: 600px) {
    .filter-container {
      flex-direction: column;
    }
  
    .filter-container input {
      width: 100%;
    }
  
    .search-button {
      margin-top: 10px;
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .filter-container {
      flex-direction: column;
    }
  
    .filter-container input {
      width: calc(100% - 20px);
      margin-right: 0;
    }
  
    .search-button {
      width: calc(100% - 20px);
      margin-left: 0;
    }
  }
  .fixed-width-badge {
    width: 130px; /* Adjust the width as per your requirement */
    display: inline-block;
    text-align: center;
  }

 
