.login-popup {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #00000090;
    display: grid;
}

.login-popup-container {
    place-self: center;
    width: max(23vw, 330px);
    color: #808080;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px 30px;
    border-radius: 8px;
    font-size: 14px;
    animation: fadeIn 0.5s;
}

.login-popup-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
}

.login-popup-title .cross-btn {
    cursor: pointer;
}

.login-popup-title h2 {
    font-size: 25px;
    font-weight: 600;
}

.login-popup-inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.role-selection {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: black;
    font-weight: bold;
}

.role-selection button {
    padding: 10px;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
    background-color: #B280A5;
    color: white;
    font-size: 15px;
    cursor: pointer;
    text-align: center;
}

.login-popup-inputs input {
    outline: none;
    padding: 10px;
    border: 1px solid #c9c9c9;
    border-radius: 4px;
}

.login-popup-container button {
    border: none;
    padding: 10px;
    border-radius: 4px;
    color: white;
    background-color: #B280A5;
    font-size: 15px;
    cursor: pointer;
}

.login-popup-condition {
    display: flex;
    align-items: start;
    gap: 8px;
    margin-top: -15px;
}

.login-popup-condition input {
    margin-top: 5px;
}

.login-popup p span {
    color: #B280A5;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 900px) {
    .login-popup-container {
        width: max(18vw, 260px);
    }
}

/* mobile */
@media (min-width: 340px) and (max-width: 420px) {

}

/* tablet */
@media (min-width: 420px) and (max-width: 768px) {

}

/* mini laptop */
@media (min-width: 768px) and (max-width: 1024px) {

}

/* desktop */
@media (min-width: 1024px) {

}
