@media (max-width: 768px){
.responsive-godown {
    width: 182%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto !important;
    margin-left: auto !important;
}
.form.Group{
    width: 182%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto !important;
    margin-left: auto !important;
}
}

.model-container-godowns{
margin-top: 50px;

}

.button-godown {
    color: white;
    border: none;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
    margin-right: 0.5rem;
    transition: background-color 0.3s;
  }
  
  .button-cancel {
    background-color: #6c757d;
  }
  
  .button-cancel:hover {
    background-color: #5a6268;
  }
  
  .button-save {
    background-color: #007bff;
  }
  
  .button-save:hover {
    background-color: #0056b3;
  }
  