.about-container {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 1200px;
    width: 100%;
}

.left-content {
    text-align: center;
    padding: 10px;
}

.left-content h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.left-content p {
    font-size: 1.2em;
    margin-bottom: 15px;
}

.right-content img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

@media (min-width: 768px) {
    .about-content {
        flex-direction: row;
        align-items: flex-start;
    }

    .left-content {
        text-align: left;
        width: 50%;
        padding-right: 20px;
    }

    .right-content {
        width: 50%;
    }

    .right-content img {
        width: 100%;
        height: auto;
    }
}
