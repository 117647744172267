.landing-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .landing-page-box {
    width: 65%;
    display: flex;
    border: 2px solid #535050;
    border-radius: 20px;
    box-shadow: 20px gray;
  }
  
  .landing-page-image {
    width: 50%; /* Image takes 50% width */
    height: auto;
    border-radius: 20px;
  }
  
  .landing-page-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-select {
    width: 89%;
    margin-bottom: 60px;
    padding: 13px;
    margin-top: 27px;
    border-radius: 10px;
    background: black;
    color: white;
    font-weight: bold;
}
.login-button {
    
    background-color: black;
    padding: 8px 41px;
    width: 59%;
    border-radius: 13px;
    color: white;
    font-weight: bold;
}
.heading-em{
    margin-top: 15px;
    font-weight: bold;
    font-size: 20px;
}
  
.login-button {
    width: 50%;
    padding: 10px;
    background-color: black;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .login-button:hover {
    background-color: #0056b3;
  }


/* Responsive styles */
@media (max-width: 1024px) {
  .landing-page-box {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
  
  .landing-page-image {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  
  .landing-page-form {
    width: 100%;
    padding: 20px;
  }
  
  .login-select {
    width: 100%;
  }
  
  .login-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .landing-page-box {
    width: 90%;
  }
  
  .heading-em {
    font-size: 18px;
  }
  
  .login-select {
    font-size: 14px;
  }
  
  .login-button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .landing-page-box {
    width: 90%;
  }
  
  .heading-em {
    font-size: 16px;
  }
  
  .login-select {
    font-size: 12px;
  }
  
  .login-button {
    font-size: 12px;
  }
}
